
import {defineComponent, ref} from "vue";
import { hideModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";


export default defineComponent({
  name:"DeleteApiModal",
  setup(){
    const deleteApiModalRef = ref<null | HTMLElement>(null);
      const formData = ref({
        active: true  
    });
    return{
      deleteApiModalRef,
      formData
    }
  },
  data() {
    return {
      id: "",
      usestatus: true,
      org:""
      // orgRole: this.getOrgRole()
    };
  },
  methods:{
    //Delete Api Function
    deletefunction(formData) {
      if (this.usestatus == true) {
        this.usestatus = false;
      } else {
        this.usestatus = true;
      }
      // formData.id = this.id;
      formData.active = this.usestatus;
      ApiService.delete(`/organizations/${this.org}/apis/${this.id}?active=${this.usestatus}`)
        .then((data) => {
         hideModal(this.deleteApiModalRef);

         this.$emit("credentialsChange");
         ElNotification({
            title: 'Success',
            message: 'Api Status Changed!',
            type: 'success',
            duration: 2000,
            position: 'top-right'
          })


        })
        .catch((error) => {
            ElNotification({
            title: 'Error',
            message: JSON.stringify(error.response.data['errors'][0]['errorMessage']) ,
            type: 'error',
            duration: 2000,
            position: 'top-right'
          })

        });
    },
    callUpdate(id, userstatus,org) {
      console.log("Test Id and User",id,userstatus)
      this.id="";
      this.usestatus=true;
      this.id = id;
      this.usestatus = userstatus;
      this.org=org;
    },
  }

});
