
import { defineComponent, ref, computed } from "vue"
import { Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { ElLoading, ElNotification } from "element-plus";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";

export default defineComponent({
    name: "editApiModule",
    components: {
        Field,
        Form
    },
    props: ["apiData"],
    data() {
        return {
            apiGroupList: [] as any,
            showTokenError: false,
            showErrorApi: false,
            errors: [],
            id: "",
            org: "",
            currApi: {} as any,
            methodList: [{ id: "POST" }, { id: "PUT" }, { id: "GET" }, { id: "PATCH" }, { id: "DELETE" }, { id: "OPTION" }],
            otherErrors: {
                postmanCollection: ""
            },
            gatewayList: ["Insurance", "Wealth Gateway", "Platform"]
        }

    },
    setup(props) {
        const editApiModalRef = ref<null | HTMLElement>(null);
        const formData = ref({
            apiName: "",
            id: "",
            apiGroup: "",
            apiProviderOrg: "",
            apiGroupName: "",
            organizationCode: "",
            apiEndpointUrl: "",
            apiEndpointUrlMethod: "",
            description: "",
            version: "",
            gatewayName: "",
            postmanCollection: "",
            mockedRequest: "",
            successRespStatusCode: "",
            expectedMockedResponse: "",
            mockedRequestContentType: "",
            mockedResponseContentType: "",
            failureRespStatusCode: "404, 502, 503, 504",
        });
        const formValidation = Yup.object().shape({
            apiName: Yup.string().label("Endpoint Name").required("Please add Endpoint Name"),
            id: Yup.string().label("Endpoint ID").required("Please Add Endpoint ID"),
            apiGroupName: Yup.string().label("API Group Name").required("Please Add API Group Name"),
            // apiEndpointUrl: Yup.string().label("API End Point Url").required("Please API End Point Url").url()
            gatewayName: Yup.string().label("Gateway Name").required("Please Gateway Name"),
            // description: Yup.string().label("Description").required("Please Add API Description"),
            failureRespStatusCode: Yup.string().label("FailureRespStatusCode").required("Please Add Failure Response Status Code"),
        });
        return {
            editApiModalRef,
            formData,
            formValidation
        }

    },
    methods: {
        //change Api Group
        changeApiGroup(){
            const apiDetaills = this.apiGroupList.find((item)=>item.id === this.formData.apiGroup);
            this.formData.description='';
            this.formData.apiGroupName = '';
            this.formData.description = apiDetaills.description;
            this.formData.apiGroupName = apiDetaills.name;
        },

        removeFields() {

            this.formData.apiName = this.currApi.apiName ? this.currApi.apiName : "",
                this.formData.id = this.currApi.id ? this.currApi.id : "",
                this.formData.apiGroup = this.currApi.apiGroup ? this.currApi.apiGroup : "",
                this.formData.apiGroupName = this.currApi.apiGroupName ? this.currApi.apiGroupName : "",
                this.formData.organizationCode = this.currApi.organizationCode ? this.currApi.organizationCode : "",
                this.formData.apiEndpointUrl = this.currApi.apiEndpointUrl ? this.currApi.apiEndpointUrl : "",
                this.formData.apiEndpointUrlMethod = this.currApi.apiEndpointUrlMethod ? this.currApi.apiEndpointUrlMethod : "",
                this.formData.description = this.currApi.description ? this.currApi.description : "",
                this.formData.version = this.currApi.version ? this.currApi.version : "",
                this.formData.gatewayName = this.currApi.gatewayName ? this.currApi.gatewayName : "",
                this.formData.mockedRequest = this.currApi.mockedRequest ? this.currApi.mockedRequest : "",
                this.formData.successRespStatusCode = this.currApi.successRespStatusCode ? this.currApi.successRespStatusCode : "",
                this.formData.expectedMockedResponse = this.currApi.expectedMockedResponse ? this.currApi.expectedMockedResponse : "",
                this.formData.mockedRequestContentType = this.currApi.mockedRequestContentType ? this.currApi.mockedRequestContentType : "",
                this.formData.mockedResponseContentType = this.currApi.mockedResponseContentType ? this.currApi.mockedResponseContentType : "",
                this.formData.postmanCollection = this.currApi.postmanCollection ? this.currApi.postmanCollection : "",
                this.formData.failureRespStatusCode = this.currApi.failureRespStatusCode ? this.currApi.failureRespStatusCode : "404, 502, 503, 504"
        },

        //remote method search
        remoteMethod(query: string) {
            const test = query;
        },
        callUpdate(id, org, apiGroupList) {
            this.id = "";
            this.id = id;
            this.org = ""
            this.org = org;
            this.apiGroupList = apiGroupList;
            this.formData.apiGroup = apiGroupList[0].id;
            const loading = ElLoading.service({
                lock: true,
                text: "Loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            ApiService.get(`/organizations/${this.org}/apis/${this.id}`)
                .then((data) => {
                    loading.close();
                    this.currApi = JSON.parse(JSON.stringify(data.data.data));
                    this.formData.apiName = this.currApi.apiName ? this.currApi.apiName : "",
                        this.formData.id = this.currApi.id ? this.currApi.id : "",
                        this.formData.apiGroup = this.currApi.apiGroup ? this.currApi.apiGroup : "",
                        this.formData.apiGroupName = this.currApi.apiGroupName ? this.currApi.apiGroupName : "",
                        this.formData.organizationCode = this.currApi.organizationCode ? this.currApi.organizationCode : "",
                        this.formData.apiEndpointUrl = this.currApi.apiEndpointUrl ? this.currApi.apiEndpointUrl : "",
                        this.formData.apiEndpointUrlMethod = this.currApi.apiEndpointUrlMethod ? this.currApi.apiEndpointUrlMethod : "",
                        this.formData.description = this.currApi.description ? this.currApi.description : "",
                        this.formData.version = this.currApi.version ? this.currApi.version : "",
                        this.formData.gatewayName = this.currApi.gatewayName ? this.currApi.gatewayName : "",
                        this.formData.postmanCollection = this.currApi.postmanCollection ? JSON.stringify(this.currApi.postmanCollection) : "",
                        this.formData.mockedRequest = this.currApi.mockedRequest ? this.currApi.mockedRequest : "",
                        this.formData.successRespStatusCode = this.currApi.successRespStatusCode ? this.currApi.successRespStatusCode : "",
                        this.formData.expectedMockedResponse = this.currApi.expectedMockedResponse ? this.currApi.expectedMockedResponse : "",
                        this.formData.mockedRequestContentType = this.currApi.mockedRequestContentType ? this.currApi.mockedRequestContentType : "",
                        this.formData.mockedResponseContentType = this.currApi.mockedResponseContentType ? this.currApi.mockedResponseContentType : "",
                        this.formData.failureRespStatusCode = this.currApi.failureRespStatusCode ? this.currApi.failureRespStatusCode : "404, 502, 503, 504"

                })
                .catch((error) => {
                    loading.close();
                    ElNotification({
                        title: 'Error',
                        message: JSON.stringify(error.response.data['errors'][0]['errorMessage']),
                        type: 'error',
                        duration: 2000,
                        position: 'top-right'
                    })
                    console.log("Error in Api Edit", error)
                })

        },
        validation_fields(){
            try{
                if(!this.formData.postmanCollection){
                    return false
                }
                if (typeof(this.formData.postmanCollection) === "object"){
                    JSON.stringify(this.formData.postmanCollection)
                }
                if((typeof(this.formData.postmanCollection) === "string")){
                    JSON.parse(this.formData.postmanCollection)
                }
                return false
            }catch (error){
                this.otherErrors.postmanCollection = "Invalid JSON field Postman Collection."
                return true
            }
        },
        //Submit form
        submitForm() {
            // debugger;
            this.otherErrors.postmanCollection = "";
            // this.validateForm();
            this.formData.postmanCollection = this.formData.postmanCollection ? this.formData.postmanCollection: "{}";
            let error = this.validation_fields()
            if (this.errors.length === 0 && !this.showTokenError && !error &&
                !this.showErrorApi) {
                if(typeof(this.formData.postmanCollection) === "string"){
                    this.formData.postmanCollection = JSON.parse(this.formData.postmanCollection)
                }
                // this.formData.postmanCollection = this.formData.postmanCollection ? JSON.parse(this.formData.postmanCollection) : {}
                // debugger;
                ApiService.patch(`/organizations/${this.org}/apis/${this.id}`, this.formData as any)
                    .then((data) => {

                        this.removeFields();
                        hideModal(this.editApiModalRef);

                        ElNotification({
                            title: 'Success',
                            message: 'Updated Api Successfully!',
                            type: 'success',
                            duration: 2000,
                            position: 'top-right'
                        })

                        this.$emit("credentialsChange");
                    })
                    .catch((error) => {
                        console.log(error)
                        ElNotification({
                            title: 'Error',
                            message: JSON.stringify(error.response.data['errors'][0]['errorMessage']),
                            type: 'error',
                            duration: 2000,
                            position: 'top-right'
                        })
                    });
            }
            if (typeof(this.formData.postmanCollection) === "object"){
                this.formData.postmanCollection = JSON.stringify(this.formData.postmanCollection)
            }
        },
    }
});
