
import { defineComponent, onMounted, ref } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElNotification, ElLoading } from "element-plus";
import Datatable from "@/components/kt-datatable/ApiEndPointTabel.vue";
import DeleteApiEndpointModule from "@/components/modals/forms/DeleteApiEndpointModule.vue";
import EditApiEndPointModule from "@/components/modals/forms/EditApiEndPointModule.vue";
import AddApiEndPointModule from "@/components/modals/forms/AddApiEndPointModule.vue";
import ApiService from "@/core/services/ApiService";



export default defineComponent({
  name: "ConfigureCredentials",
  components: {
    Datatable,
    DeleteApiEndpointModule,
    EditApiEndPointModule,
    AddApiEndPointModule
  },
  data() {
    return {
      apiData: [],
      page: 1,
      count: 25,
      currApi: {} as any,
      id: 0,
      totalCount: 0,
      search: "",
      searchFlag: true,
      initApi: [],
      filterCred: {},
      orgList: [],
      SelectedOrg: "1SB",
      apiGroupList: []
    };


  },
  methods: {
    //Get Master Data
    //getMasterData
    getMasterData() {
      ApiService.get("/master?organizationId=1SB")
        .then((data) => {
          const { APIGroup } = data.data.data
          this.apiGroupList = APIGroup;

        })
        .catch((error) => {
          console.log("Error", error)
        })
    },
    //delete Api
    enablefunction(Id, Status, org) {

      (this.$refs["deleteCredentialForm"] as any).callUpdate(

        Id,
        Status,
        org
      );
    },
    //edit Apis
    editApiEndPointMethod(Id, org) {

      (this.$refs["editCredentialForm"] as any).callUpdate(

        Id,
        org,
        this.apiGroupList
      );
    },
    //addApiEndPint
    addApiEndPointMethod() {

      (this.$refs["addEndpointForm"] as any).callUpdate(
        this.apiGroupList,
        this.orgList
      );
    },
    //setCountPage And call Apis
    setCountPage(data) {
      const { count, page } = data;
      this.page = page;
      this.count = count;
      this.getApiEndPoint()



    },

    //Change Track Get Api After Update
    ChangeCredList() {
      this.getApiEndPoint();
    },

    //Get getOrganizations
    getOrganization() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get('/organizations')
        .then((data) => {
          this.orgList = data.data.data.organization_list;
          this.totalCount = data.data.data.total_count;
          this.getApiEndPoint();
          loading.close();




        })
        .catch((error) => {

          loading.close();
          console.log("Error in Get Organization", error)
        })
    },
    //Get Api End Point
    getApiEndPoint() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(`/organizations/${this.SelectedOrg}/apis?featureMode=latest_endpoints&pageNumber=${this.page}&pageSize=${this.count}`)
        .then((data) => {
          this.apiData = data.data.data.api_list;
          loading.close();
          this.setinitCred(this.apiData)

        })
        .catch((error) => {

          console.log("Error in Get Organization", error)
        })
    },
    //search Item
    searchItems() {
      this.apiData.splice(
        0,
        this.apiData.length,
        ...this.initApi
      );

      if (this.search !== "") {
        let results = []
        for (let j = 0; j < this.apiData.length; j++) {
          if (this.searchingFunc(this.apiData[j], this.search)) {
            results.push(this.apiData[j]);
          }

        }
        this.apiData.splice(
          0,
          this.apiData.length,
          ...results
        );
        if (this.apiData.length == 0 && this.searchFlag == true) {
          this.searchFlag = false
          ElNotification({
            title: 'Warning',
            message: 'No Records Found!',
            type: 'warning',
            duration: 2000,
            position: 'top-right'
          })
        }
        else if (this.apiData.length != 0) {
          this.searchFlag = true
        }



      }
    },
    //Searching Function
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object") && !(typeof obj[key] === "boolean")) {
          if (obj[key] != "") {
            if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
              return true;
            }
          }

        }
      }
      return false;
    },
    //setinitial Api
    async setinitCred(apis) {
      this.apiData = await JSON.parse(JSON.stringify(apis));
      // this.totalCount=totalCount

      this.initApi.length = 0;
      this.initApi.splice(
        0,
        this.apiData.length,
        ...this.apiData
      );
      this.search = "";

    },
    //set filter
    setFilter(filterCred) {
      this.filterData.manufacturerCode = filterCred.manufacturerCode,
        this.filterData.credentialOrgType = filterCred.credentialOrgType
      this.filterData.consumerCode = filterCred.consumerCode,
        this.filterData.apiProviderOrg = filterCred.apiProviderOrg,
        this.filterData.serviceName = filterCred.serviceName,
        this.filterData.productGroupId = filterCred.productGroupId
      // this.getCredentials(this.page, this.count);
    }
  },
  //Hook Used to call Api 
  created() {

    this.getOrganization();
    this.getMasterData();

  },
  setup() {
    const filterData = ref({
      manufacturerCode: "",
      credentialOrgType: "",
      consumerCode: "",
      apiProviderOrg: "",
      serviceName: "",
      productGroupId: "",
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("API EndPoints Configuration", ["Manage"]);
    });

    const tableHeader = ref([
      {
        name: "EndPoint ID",
        key: "id",
        sortable: true,
      },
      {
        name: "Endpoint Name",
        key: "name",
        sortable: true,
      },

      {
        name: "API Group ID",
        key: "apiGroup",
        sortable: true,
      },
      {
        name: "Api Group Name",
        key: "apiGroupName",
        sortable: true,
      },
      {
        name: "Api EndPoint URL Method",
        key: "apiEndpointUrlMethod",
        sortable: true
      },
      {
        name: "Api EndPoint URL",
        key: "apiEndpointUrl",
        sortable: true,
      },
      {
        name: "Status",
        key: "active",
        sortable: true,
      },
      {
        name: "Edit Action",
        key: "actions",
      },
    ]);

    return {
      filterData,
      tableHeader

    };
  },
});
