
  import { computed, defineComponent, ref, onMounted, watch} from "vue";
  import arraySort from "array-sort";
  
  interface IPagination {
    page: number;
    total: number;
    rowsPerPage: number;
  }
  
  export default defineComponent({
    name: "datatable",
    props: {
      tableHeader: { type: Array, required: true },
      tableData: { type: Array, required: true },
      enableItemsPerPageDropdown: Boolean,
      rowsPerPage: Number,
      disablePagination: Boolean,
      totalCount:Number
    },
   
    components: {},
    setup(props, { emit }) {
  
      const data = computed(()=>props.tableData);
      const totalCount = computed(()=>props.totalCount)
      const currentSort = ref<string>("");
      const click = ref<number>(1);
      const pagination = ref<IPagination>({
        page: 1,
        total: 0,
        rowsPerPage: 25,
      });
  
      //onUpdateCount
     const onCountChange = (event) =>
      {
        emit("setCountPage",{count:pagination.value.rowsPerPage, page:pagination.value.page})
      };
      watch(totalCount, () => {
        pagination.value.total = totalCount.value;
      });
      onMounted(() => {
        pagination.value.rowsPerPage = props.rowsPerPage ? props.rowsPerPage : 25;
        pagination.value.total = totalCount.value;
      });
      
      
      const getItems = computed(() => {
        const clone = JSON.parse(JSON.stringify(data.value));
        // const startFrom =
        //   pagination.value.page * pagination.value.rowsPerPage -
        //   pagination.value.rowsPerPage;
        // return clone.slice(startFrom, pagination.value.rowsPerPage);
        return clone;
      });
      const pages = computed(() => {
        return Math.ceil(pagination.value.total / pagination.value.rowsPerPage);
      });
      const setCurrent = (val) => {
       if( pagination.value.page != val) 
       {
        pagination.value.page = val; 
        emit("setCountPage",{count:pagination.value.rowsPerPage, page:pagination.value.page})
       }
       
      };
      const sort = (columnName, sortable) => {
        if (!sortable) {
          return;
        }
  
        if (click.value === 2) {
          click.value = 1;
          arraySort(data.value, columnName, { reverse: false });
        } else {
          click.value++;
          arraySort(data.value, columnName, { reverse: true });
        }
        currentSort.value = columnName + click.value;
      };
  
      return {
        pagination,
        pages,
        setCurrent,
        getItems,
        sort,
        currentSort,
        onCountChange
      };
    },
  });
  